export const GET_SELLERS = "GET_SELLER";
export const GET_SELLERS_ID = "GET_SELLER_ID";
export const GET_DIRECTORS = "GET_DIRECTORS";
export const GET_MANAGERS = "GET_MANAGERS";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_ADMINISTRATORS = "GET_ADMINISTRATORS";
export const GET_PROMOTORS = "GET_PROMOTORS";
export const GET_COORDINATORS = "GET_COORDINATORS";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_STATES = "GET_STATES";
export const GET_CITIES = "GET_CITIES";
export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_EVENT_TYPES = "GET_EVENT_TYPES";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_RESOURCES = "GET_RESOURCES";
export const GET_CALENDAR = "GET_CALENDAR";
export const GET_STATUS_APPOINTMENT = "GET_STATUS_APPOINTMENT";
export const GET_ENTITY_TYPE = "GET_ENTITY_TYPE";
export const GET_APPOINTMENTS = "GET_APPOINTMENTS";
export const GET_DAY_CALENDAR = "GET_DAY_CALENDAR";
export const GET_STATUS_EVENT = "GET_STATUS_EVENT";
export const GET_REQUEST_EVENT_STATUS = "GET_REQUEST_EVENT_STATUS";
export const GET_SALES = "GET_SALES";
export const GET_ALL_EVENT_ASSIGNED_USER = "GET_ALL_EVENT_ASSIGNED_USER";
export const GET_QR_CODE = "GET_QR_CODE";
export const GET_ALL_GOALS_MANGERS = "GET_ALL_GOALS_MANGERS";
export const GET_ALL_GOALS_SELLERS = "GET_ALL_GOALS_SELLERS";
export const GET_ALL_GOALS = "GET_ALL_GOALS";
export const GET_ALL_CLIENTS = "GET_ALL_CLIENTS";
export const GET_CLIENT_ID = "GET_CLIENT_ID";
export const GET_STATUS = "GET_STATUS";
export const GET_ROLES = "GET_ROLES";
export const GET_UBICATIONS = "GET_UBICATIONS";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const GET_TEAM = "GET_TEAM";
export const GET_ZONES = "GET_ZONES";
export const GET_PLACES_MAPS = "GET_PLACES_MAPS";
export const GET_EVENTS_PENDING = "GET_EVENTS_PENDING";
export const GET_POS_PENDING = "GET_POS_PENDING";
export const GET_ALL_DATE_FRECUENCY = "GET_ALL_DATE_FRECUENCY";
export const GET_MY_EVENTS = "GET_MY_EVENTS";
export const GET_EVENTS_AVAILABLE = "GET_EVENTS_AVAILABLE";
export const GET_POS_AVAILABLE = "GET_POS_AVAILABLE";
export const GET_MY_POS = "GET_MY_POS";
export const GET_SALE_TYPE = "GET_SALE_TYPE";
export const GET_SOURCE_BRC = "GET_SOURCE_BRC";
export const GET_ELIGIBLE_COVER = "GET_ELIGIBLE_COVER";
export const GET_REMINDER_PERIOD = "GET_REMINDER_PERIOD";
export const GET_SALE_STATUS = "GET_SALE_STATUS";
export const GET_CALENDAR_TYPE = "GET_CALENDAR_TYPE";
export const GET_MEDICINE = "GET_MEDICINE";
export const GET_DOCTORS = "GET_DOCTORS";
export const GET_DOCTORS_SPECIALIST = "GET_DOCTORS_SPECIALIST";
export const GET_BRC = "GET_BRC";
export const GET_PERMISSIONS_USER = "GET_PERMISSIONS_USER";
export const GET_ROLE_CATEGORIES = "GET_ROLE_CATEGORIES";
export const GET_ROLE_CATEGORIES_EXCLUDE_ADMIN = "GET_ROLE_CATEGORIES_EXCLUDE_ADMIN";
export const GET_ICD_CODE = "GET_ICD_CODE";
export const GET_SHORT_DESCRIPTION = "GET_SHORT_DESCRIPTION";
export const GET_LONG_DESCRIPTION = "GET_LONG_DESCRIPTION";
export const GET_DATE_FRECUENCY_HISTOGRAM = "GET_DATE_FRECUENCY_HISTOGRAM";
export const GET_IPA_GROUP = "GET_IPA_GROUP";
